@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&display=swap");

/* Reset */
* {
  font-family: inherit;
}

html {
  background-color: transparent;
}

body {
  background-color: var(--backgroundColor);
  color: var(--text-color);
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}

.dark {
  --tableHoverColor: var(--colorGray6);
  --background-color: var(--grayHeadline);
  --primary-color: var(--grayHeadline);
  --text-color: var(--colorWhite);
  --tabsColor: var(--colorWhite);
  --backgroundColor: var(--grayHeadline);
  --fuwBrandTextColor: #e9e9e9;
  --regularTextColor: var(--colorGray1);
  --greyTextColor: var(--colorGray1);
  --arrowButtonSvgBackground: #555;
  --arrowButtonSvgArrow: #fff;
  --tabNavLineColor: var(--colorWhite);
  --boxHeaderColor: var(--colorGray6);
  --fuwBrandBackgroundColor: #0759d5;
  --tooltipTextColor: var(--colorGray1);
  --shadowColor: rgb(255, 255, 255, 50%);
  --arrowColor: var(--colorWhite);
  --chartPriceGreenColor: #d1e3d7;
  --chartPriceRedColor: #f4cccf;
  --chartDateColor: var(--colorGray1);
  --01BrandColorsBlueMain: #e9e9e9;
  --colorGray5: #e9e9e9;
  --lineColor: #e9e9e9;

  /* Gradients */
  --leftGradient: linear-gradient(to right, rgba(18, 18, 18, 100%), rgba(18, 18, 18, 0%));
  --rightGradient: linear-gradient(to left, rgba(18, 18, 18, 100%), rgba(18, 18, 18, 0%));
}

:root {
  /* Defined by us */
  --fuwBrandTextColor: #043785;
  --fuwBrandBackgroundColor: #12398e;
  --tableHoverColor: #f4f4f4;
  --primary-color: var(--colorWhite);
  --backgroundColor: transparent;
  --regularTextColor: var(--grayHeadline);
  --greyTextColor: var(--colorGray5);
  --arrowButtonSvgBackground: #d5d7d7;
  --arrowButtonSvgArrow: #202346;
  --greenColor: #1d955b;
  --redColor: #d33440;
  --chartPriceGreenColor: #1d955b;
  --chartPriceRedColor: #d33440;
  --chartDateColor: var(--grayHeadline);
  --lineColor: var(--grayHeadline);

  /* Defined in Zeplin */
  --01BrandColorsBlueMain: #202346;
  --colorBeigeLight: #f2f0ea;
  --colorYellow: #ffe462;
  --colorWhite: #fff;
  --colorGray1: #e9e9e9;
  --colorGray2: #d4d4d4;
  --colorGray3: #bebebe;
  --colorGray4: #999;
  --colorGray5: #7d7d7d;
  --colorGray6: #555;
  --colorGray7: #262525;
  --grayHeadline: #121212;
  --colorTa: #0078bd;
  --colorBz: #800000;
  --colorBu: #0085ab;
  --colorZrzTdg: #28468c;
  --colorBaz24h: #b61130;

  /* Define colors for different elements */

  --background-color: transparent;
  --text-color: var(--colorGray6);
  --tabsColor: var(--colorGray7);
  --tabNavLineColor: var(--grayHeadline);
  --boxHeaderColor: #f4f4f4;
  --tooltipTextColor: var(--01BrandColorsBlueMain);
  --shadowColor: rgb(113, 113, 113, 50%);
  --arrowColor: var(--01BrandColorsBlueMain);

  /* Gradients */
  --leftGradient: linear-gradient(to right, rgba(255, 255, 255, 100%), rgba(255, 255, 255, 0%));
  --rightGradient: linear-gradient(to left, rgba(255, 255, 255, 100%), rgba(255, 255, 255, 0%));
}

.tooltip {
  display: flex;
}

